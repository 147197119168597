
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import * as _ from "lodash";
import { CreateReqRequest } from "@/services/requests/CreateReqRequest";
import requestService from "@/services/RequestService";
import { useWidget } from "@/composables/useWidget";
import dayjs from "dayjs";
import LookupService from "@/services/LookupService";
import { GetFieldsResponse } from "@/services/responses/GetFieldsResponse";
import { GetExpertsResponse } from "@/services/responses/GetExpertsResponse";
import Popup from "./popup.vue";

export default defineComponent({
  components: { Popup },
  setup() {
    const { t } = useI18n();
    const loading = ref(false);
    const { fetchData, companyAndRoleVisible, notesVisible, chkPrivacyVisible, chkDataConsentVisible, chkMarketingVisible, twoStepsVersion, privacyPolicyLink, marketingPolicyLink, appLang, chkPrivacyCallback, defaultCustomerDataCallback } = useWidget();
    const hours = ref<{ hourValue: number, displayValue: string }[]>([]);
    const dateDisabled = ref(true);
    const popupResult = ref(null);
    // const validation = ref(true);
    const validationFirstName = ref(null as boolean);
    const validationLastName = ref(null as boolean);
    const validationEmail = ref(null as boolean);
    const validationPhone = ref(null as boolean);
    const validationCompany = ref(null as boolean);
    const validationRole = ref(null as boolean);
    const validationPrivacyChk = ref(null as boolean);
    const validationDataChk = ref(null as boolean);

    const fields = ref<GetFieldsResponse[]>([]);
    const experts = ref<GetExpertsResponse[]>([]);

    const custType = ref('field');
    const custFirstName = ref('');
    const custLastName = ref('');
    const custEmail = ref('');
    const custPhone = ref('');
    const custCompany = ref('');
    const custRole = ref('');
    const custAsSoon = ref(true);
    const custRequiredDate = ref(dayjs());
    const custRequiredHour = ref<number>();
    const custFieldId = ref<number>();
    const custExpertId = ref<number>();
    const custNotes = ref('');
    const custPrivacyChecked = ref(false);
    const custDataConsentChecked = ref(false);
    const custMarketingChecked = ref(false);

    const custHaveReadPrivacyPolicy = ref(false);

    const showThanksMsg = ref(false);
    const isCreating = ref(false);

    const stepVisible = ref(1);


    const addActiveClass = (el: any) => {
        return el.target.previousElementSibling.classList.add("active");
    };

    const removeActiveClass = (el: any) => {
        if (el.target.value.length === 0)
            return el.target.previousElementSibling.classList.remove("active");
    };



    onMounted(async () => {
    //   console.log('OnDemandForm mounted!');

      const returnedFields = await fetchData(loading, LookupService.getFields());
    //   console.log(returnedFields);
      fields.value = _.filter(returnedFields, function(f: GetFieldsResponse ) { return f.visible; });

      const returnedExperts = await fetchData(loading, LookupService.getExperts());
    //   console.log(returnedExperts);
      experts.value = _.filter(returnedExperts, function(e: GetExpertsResponse ) { return e.visible; });

      for (let hour = 9; hour < 19; hour++) {
        hours.value.push({
          hourValue: hour,
          displayValue: `${hour}:00`
        });
      }
      custRequiredHour.value = hours.value[0].hourValue;

      if(defaultCustomerDataCallback) {
            const data = await defaultCustomerDataCallback();
            if(data.firstName) {
                custFirstName.value = data.firstName;
            }
            if(data.lastName) {
                custLastName.value = data.lastName;
            }
            if(data.eMail) {
                custEmail.value = data.eMail;
            }
            if(data.phoneNumber) {
                custPhone.value = data.phoneNumber;
            }
            if(data.company) {
                custCompany.value = data.company;
            }
            if(data.role) {
                custRole.value = data.role;
            }
        }
    });

    const today = computed(() => {
        return dayjs().format("YYYY-MM-DD");
    });

    const titleExists = computed(() => {
        return t('common.title') != '';
    });

    const claimExists = computed(() => {
        return t('common.claim') != '';
    });

    const pageTitleExists = computed(() => {
        return t('common.pageTitle') != '';
    });

    const labelTypeExists = computed(() => {
        return t('common.labelType') != '';
    });

    const pageTitle = computed(() => {
        return t('common.pageTitle').replace('#', stepVisible.value.toString());
    });

    const personalDataLabelExists = computed(() => {
        return t('common.personalDataLabel') != '';
    });

    const labelWeWillContactYouExists = computed(() => {
        return t('common.labelWeWillContactYou') != '';
    });

    const custRequiredDateModel = computed({
      get(): string {
        return custRequiredDate.value.format("YYYY-MM-DD");
      },
      set(newValue: string): void {
        custRequiredDate.value = dayjs(newValue);
      }
    });

    const appCulture = computed(() => {
        if(appLang.value == 'it') {
          return 'it-IT';
        } else {
          return 'en-US';
        }
    })

    const createDisabled = computed(() => (chkPrivacyVisible.value == true && custPrivacyChecked.value != true) || (chkDataConsentVisible.value == true && custDataConsentChecked.value != true) );
    // const dateDisabled = computed(() => custAsSoon.value == true );

    watch(custFirstName, (newValue) => {
      validationFirstName.value = newValue != '';
    })
    watch(custLastName, (newValue) => {
      validationLastName.value = newValue != '';
    })
    watch(custEmail, (newValue) => {
      validationEmail.value = newValue != '' && validateEmail(custEmail.value);
    })
    watch(custPhone, (newValue) => {
      validationPhone.value = newValue != '';
    })
    watch(custCompany, (newValue) => {
      validationCompany.value = newValue != '';
    })   
    watch(custRole, (newValue) => {
      validationRole.value = newValue != '';
    })
    watch(custPrivacyChecked, (newValue) => {
      validationPrivacyChk.value = newValue;
    })     
    watch(custDataConsentChecked, (newValue) => {
      validationDataChk.value = newValue;
    })      
    
    const validationBlocked = computed(() => (
        validationFirstName.value != null && validationFirstName.value == false || 
        validationLastName.value != null && validationLastName.value == false || 
        validationPhone.value != null && validationPhone.value == false  || 
        validationEmail.value != null && validationEmail.value == false ||
        validationCompany.value != null &&  validationCompany.value == false || 
        validationRole.value != null &&  validationRole.value == false ||
        validationPrivacyChk.value != null && validationPrivacyChk.value == false || 
        validationDataChk.value != null && validationDataChk.value == false ))

    watch(custAsSoon, () => {
    //   console.log('The new custAsSoon value is: ' + custAsSoon.value)
      dateDisabled.value = !dateDisabled.value;
    //   console.log('The new dateDisabled value is: ' + dateDisabled.value)
    })

    watch(custType, (newValue) => {
      if(newValue === 'field') {
        custExpertId.value = null;
      } else {
        custFieldId.value = null;
      }
    })

    const goBack = () => {
        stepVisible.value = 1;
    }

    const goOn = async () => {
        stepVisible.value = 2;
    }

    const privacyCheck = async (e: Event, fromLink: boolean) => {  
        if(fromLink || !custHaveReadPrivacyPolicy.value) {
            e.preventDefault();
        }

        if(chkPrivacyCallback) {
            if(custHaveReadPrivacyPolicy.value == false) {
                custHaveReadPrivacyPolicy.value = await chkPrivacyCallback(fromLink);
            } else {
                if(!fromLink) {
                    // questo è il normale comportamento del check
                    custPrivacyChecked.value = !custPrivacyChecked.value;
                } else {
                    // la chiamata alla funzione la devo fare sempre se vengo dal link, per mostrare comunque l'informativa !!!
                    custHaveReadPrivacyPolicy.value = await chkPrivacyCallback(fromLink);
                }
            }
        }
    }

    const create = async () => {

      validationFirstName.value = custFirstName.value != '';
      validationLastName.value = custLastName.value != '';
      validationPhone.value = custPhone.value != '';
      validationEmail.value = custEmail.value != '' && validateEmail(custEmail.value);
      validationCompany.value = !companyAndRoleVisible.value || custCompany.value != '';
      validationRole.value = !companyAndRoleVisible.value || custRole.value != '';

      if(validationFirstName.value == false|| validationLastName.value == false || validationPhone.value == false  || validationEmail.value == false
          || validationCompany.value == false || validationRole.value == false
          || (chkPrivacyVisible.value == true && custPrivacyChecked.value != true) || (chkDataConsentVisible.value == true && custDataConsentChecked.value != true) )
      {
        // validation.value = false;
        // console.log('Validation KO');
        
        validationPrivacyChk.value = !(chkPrivacyVisible.value == true && custPrivacyChecked.value != true);
        validationDataChk.value  = !(chkDataConsentVisible.value == true && custDataConsentChecked.value != true);

        return;
      }

    //   console.log('Validation ok');

      isCreating.value = true;

      let reqDate = custRequiredDate.value
          .hour(custRequiredHour.value)
          .minute(0)
          .second(0)
          .millisecond(0)   
          .toDate();

      if(custAsSoon.value == true) {
        reqDate = null;
      }

      const request : CreateReqRequest = {
        fieldId: custFieldId.value,
        expertId: custExpertId.value,  
        asSoonAsPossible: custAsSoon.value,
        requiredDate: reqDate,
        firstName: custFirstName.value,
        lastName: custLastName.value,
        phoneNumber: custPhone.value,
        company: custCompany.value,
        role: custRole.value,
        emailAddress: custEmail.value,
        notes: custNotes.value,
        language: appCulture.value,
        privacyChecked: chkPrivacyVisible.value ? custPrivacyChecked.value : null,
        dataConsentChecked: chkDataConsentVisible.value ? custDataConsentChecked.value : null,
        marketingChecked: chkMarketingVisible.value ? custMarketingChecked.value : null
      }
    //   console.log(request);
      const reqCreated = await fetchData(loading, requestService.createRequest(request))
    //   console.log(reqCreated);
      if(reqCreated && reqCreated.id && reqCreated.id != 0) {
        showThanksMsg.value = true;
        // popupResult.value.show(); 
      }    
      isCreating.value = false; 
    }

    const validateEmail = (email: string): boolean => {
      // prettier-ignore
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    return { t, hours, fields, experts, popupResult, validationFirstName, validationLastName, validationEmail, validationPhone,
        custCompany, custRole, validationCompany, validationRole, validationPrivacyChk, validationDataChk,
        custType, custFirstName, custLastName, custEmail, custPhone, custAsSoon, custRequiredDateModel, custRequiredHour, today,
        custFieldId, custExpertId, custNotes, custPrivacyChecked, custDataConsentChecked, custMarketingChecked, 
        create, createDisabled, dateDisabled, companyAndRoleVisible, notesVisible, chkPrivacyVisible, chkDataConsentVisible, chkMarketingVisible, twoStepsVersion, isCreating,
        privacyPolicyLink, marketingPolicyLink, showThanksMsg, titleExists, claimExists, stepVisible, goBack, goOn, privacyCheck,
        pageTitleExists, labelTypeExists, pageTitle, personalDataLabelExists, labelWeWillContactYouExists, chkPrivacyCallback, addActiveClass, removeActiveClass, validationBlocked }
  }
});
